<template>
    <login-and-regist-layout :title="$t('login.title')" :loading="isLoading">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
                <v-row>
                    <v-col md="10" class="mx-auto">
                        <v-text-field
                            v-model="phoneNumber"
                            :label="$t('login.phoneNumber')"
                            type="number"
                            :placeholder="$t('login.phoneNumberPlaceholder')"
                            prepend-inner-icon="mdi-phone"
                            persistent-placeholder
                            :rules="fieldRule(phoneNumberRule)"
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            v-model="password"
                            :label="$t('login.password')"
                            :placeholder="
                                $t('formField.formFieldPlaceholder', {
                                    field: $t('login.password'),
                                })
                            "
                            type="password"
                            :rules="fieldRule(passwordRule)"
                            prepend-inner-icon="mdi-lock"
                            persistent-placeholder
                            :autocomplete="false"
                            validate-on-blur
                        ></v-text-field>
                        <router-link
                            to="/forgot-password"
                            class="text-decoration-none"
                        >
                            <p class="text-caption text-right grey--text">
                                {{ $t("login.forgotPassword") }}
                            </p>
                        </router-link>
                        <v-btn
                            block
                            rounded
                            class="primary mt-5"
                            @click="onLogin"
                            :loading="isLoading"
                        >
                            {{ $t("login.title") }}
                        </v-btn>
                        <p class="text-center mt-10 mb-3">
                            {{ $t("login.signUpSuggest") }}
                        </p>
                        <router-link to="/sign-up" class="text-decoration-none">
                            <p
                                class="text-center text-subtitle-1 text-uppercase"
                            >
                                {{ $t("signUp.title") }}
                            </p>
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </login-and-regist-layout>
</template>

<script>
import LoginAndRegistLayout from "@/layout/LoginAndRegistLayout.vue";
import { mapActions, mapGetters } from "vuex";
import commonFunc from "@/common/commonFunc.js";
import screen from "@/mixins/screen.js";

export default {
    name: "Login",
    components: { LoginAndRegistLayout },
    mixins: [screen],
    data() {
        return {
            phoneNumber: "",
            password: "",
            errorMsg: "",
            valid: true,
            phoneNumberRule: [
                (v) =>
                    !!v ||
                    this.$t("formField.formFieldRequired", {
                        field: this.$t("login.phoneNumber"),
                    }),
                (v) =>
                    /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/.test(v) ||
                    this.$t("formField.formFieldInvalid", {
                        field: this.$t("login.phoneNumber"),
                    }),
            ],
            passwordRule: [
                (v) =>
                    !!v ||
                    this.$t("formField.formFieldRequired", {
                        field: this.$t("signUp.password"),
                    }),
            ],
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: "auth/isAuthenticated",
        }),
    },
    watch: {
        isAuthenticated(newVal) {
            if (newVal) {
                this.$router.push("/marketing"); 
            }
        },
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },
        onLogin() {
            this.validate();
            if (this.valid) {
                let phone = commonFunc.formatPhone(this.phoneNumber);
                this.userLogin({
                    user_name: phone,
                    password: this.password,
                });
            }
        },
        ...mapActions({
            userLogin: "auth/customerLogin",
        }),
    },
};
</script>

<style scoped>
</style>